import { UserProfileService } from './services/user-profile.service';
import { UserProfile } from './types/user-profile.type';

/**
 * A user profile helper to get a list of profiles (users) that can be used for tagging users in comments, selecting users in the user selector
 */
class UserProfileHelpers {
    // a static holder for all the user profiles. this makes sure we only do the request just once
    static loadedUserProfiles: { [key: string]: UserProfile } | null = null;

    // A promise to ensure only one load request is made at a time
    private static loadPromise: Promise<void> | null = null;

    /**
     * get a user profile by id
     * @param userId the id of the user
     * @returns a profile object
     */
    public static async getUserProfile(userId: string | number): Promise<UserProfile | undefined> {
        await this.loadUserProfiles();

        return this.loadedUserProfiles?.[userId];
    }

    /**
     * get a user profile by username
     * @param username the username of the user
     * @returns a profile object
     */
    public static async getUserProfileByUsername(username: string): Promise<UserProfile | undefined> {
        await this.loadUserProfiles();

        return Object.values(this.loadedUserProfiles ?? {}).find((user) => user.username === username);
    }

    /**
     * get a list of user profiles
     * @returns a list of profiles
     */
    public static async getUserProfiles(): Promise<UserProfile[]> {
        await this.loadUserProfiles();

        return Object.values(this.loadedUserProfiles ?? {});
    }

    private static async loadUserProfiles() {
        if (this.loadedUserProfiles) return;

        // If there's already a load request in progress, return its promise
        if (this.loadPromise) {
            await this.loadPromise;
            return;
        }

        // Otherwise, create a new load request
        this.loadPromise = UserProfileService.getUserProfiles().then((userProfiles) => {
            this.loadedUserProfiles = userProfiles.reduce(
                (acc, userProfile) => {
                    acc[userProfile.id] = userProfile;
                    return acc;
                },
                {} as { [key: string]: UserProfile }
            );
            // Clear the load promise once the data is loaded
            this.loadPromise = null;
        });

        await this.loadPromise;
    }
}

export { UserProfileHelpers };
