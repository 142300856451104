export default class FilterHelpers {
    /**
     * Create one filter object to use in the filterSetup.
     * @param {sting} label
     * @param {sting} name
     * @param {string} type
     * @param {object} source
     * @param {string} valueKey
     * @returns
     */
    static getFilter = (label, name, type, source, valueKey) => {
        const filter = {
            label,
            name,
            type,
            defaulValue: [],
            options: []
        };
        Object.entries(source).forEach(([key, value]) => {
            filter.options.push({ key, value: valueKey ? value[valueKey] : value });
        });
        return filter;
    };

    /**
     * Filter a list given filters a search term and the fileds the serach term should be used on.
     * @param {array} list to filter
     * @param {object} filters
     * @param {array} searchFields the fields to perform the text search on
     * @returns filtered list
     */
    static handleFilter = (list, filters, searchFields = []) => {
        let filteredList = list;

        if (!filters) return filteredList;

        // Loop through the set filters and filter accordingly.
        Object.keys(filters).forEach((name) => {
            const filterValue = filters[name];

            if (name === 'searchTerm') {
                filteredList = filteredList.filter((item) => {
                    let found = false;
                    searchFields.forEach((field) => {
                        if (filterValue) {
                            let searchIsValidRegEx = true;

                            // Get the lowercase search value and escape special characters.
                            const searchValue = filterValue.toLowerCase().replace(/\+/g, '\\+');

                            try {
                                new RegExp(searchValue);
                            } catch (e) {
                                searchIsValidRegEx = false;
                            }

                            if (searchIsValidRegEx) {
                                if (item[field] && item[field].search && item[field].toLowerCase().search(searchValue) > -1) {
                                    found = true;
                                }
                            } else {
                                if (item[field] && item[field].search && item[field].toLowerCase().indexOf(searchValue) > -1) {
                                    found = true;
                                }
                            }
                        } else {
                            found = true;
                        }
                    });
                    return found;
                });
            } else if (Array.isArray(filterValue) && filterValue.length > 0) {
                // Filter is MultiSelect, filter value is an array.
                filteredList = filteredList.filter((listItem) => {
                    if (Array.isArray(listItem[name])) {
                        return filterValue.some((v) => listItem[name].includes(v));
                    } else {
                        return filterValue.includes(listItem[name]);
                    }
                });
            } else if (!!filterValue && filterValue.length > 0) {
                // Filter is single select.
                filteredList = filteredList.filter((listItem) => {
                    if (Array.isArray(listItem[name])) {
                        return listItem[name].includes(filterValue);
                    } else {
                        return filterValue == listItem[name];
                    }
                });
            }
        });

        return filteredList;
    };
}
